var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","value":_vm.dialog,"width":"700"}},[_c('AppDialog',{attrs:{"heading":{
			label: _vm.isUserExist
				? _vm.$t('app.add_existing_student')
				: _vm.$t('app.create_new_student'),
		},"action":{
			label: 'app.save',
			event: 'save',
		}},on:{"save":function($event){return _vm.save()},"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [_c('v-breadcrumbs',{staticClass:"px-0 pt-0",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"item",fn:function(ref){
		var item = ref.item;
return [_c('v-breadcrumbs-item',{class:{
							'info--text ': true,
						}},[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])]}},{key:"divider",fn:function(){return [_c('v-icon',[_vm._v(_vm._s(_vm.$vuetify.rtl ? _vm.$vuetify.icons.values.left_chevron : _vm.$vuetify.icons.values.right_chevron))])]},proxy:true}])}),_c('v-form',{ref:"form"},[(!_vm.isUserExist)?[_c('v-text-field',{attrs:{"outlined":"","color":"greenAccent2","background-color":"white","label":_vm.$t('app.placeholder.username'),"rules":[_vm.rules.required]},model:{value:(_vm.fields.login_user),callback:function ($$v) {_vm.$set(_vm.fields, "login_user", $$v)},expression:"fields.login_user"}}),_c('v-text-field',{attrs:{"outlined":"","color":"greenAccent2","background-color":"white","label":_vm.$t('app.placeholder.password'),"type":_vm.show_password ? 'text' : 'password',"rules":[_vm.rules.required, _vm.rules.strongPassword],"append-icon":_vm.$vuetify.icons.values[_vm.show_password ? 'eye' : 'eye_off']},on:{"click:append":function($event){_vm.show_password = !_vm.show_password}},model:{value:(_vm.fields.password),callback:function ($$v) {_vm.$set(_vm.fields, "password", $$v)},expression:"fields.password"}}),_c('v-text-field',{attrs:{"outlined":"","color":"greenAccent2","label":_vm.$t('app.first_name'),"rules":[_vm.rules.required]},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}}),_c('v-text-field',{attrs:{"outlined":"","color":"greenAccent2","background-color":"white","label":_vm.$t('app.last_name'),"rules":[_vm.rules.required]},model:{value:(_vm.fields.last_name),callback:function ($$v) {_vm.$set(_vm.fields, "last_name", $$v)},expression:"fields.last_name"}}),_c('v-select',{attrs:{"items":_vm.$defines.GENDER,"item-text":"name","item-value":"value","outlined":"","rules":[_vm.rules.required],"label":_vm.$t('app.gender'),"color":"greenAccent2"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
						var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")]}},{key:"item",fn:function(ref){
						var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")]}}],null,false,2685184572),model:{value:(_vm.fields.gender),callback:function ($$v) {_vm.$set(_vm.fields, "gender", $$v)},expression:"fields.gender"}}),_c('v-text-field',{attrs:{"placeholder":_vm.$t('app.unique_id'),"outlined":"","color":"greenAccent2"},model:{value:(_vm.fields.external_id),callback:function ($$v) {_vm.$set(_vm.fields, "external_id", _vm._n($$v))},expression:"fields.external_id"}})]:[_c('v-text-field',{attrs:{"type":"number","placeholder":_vm.$t('app.user_id'),"outlined":"","rules":[_vm.rules.required],"color":"greenAccent2"},model:{value:(_vm.fields.user_id),callback:function ($$v) {_vm.$set(_vm.fields, "user_id", $$v)},expression:"fields.user_id"}})]],2)]},proxy:true}]),model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }