<template>
	<table class="table-wr" v-if="groupPlanLength && !loading">
		<!-- FIRST ROW -->
		<tr>
			<td colspan="4" rowspan="2">
				<div>
					{{ $t("app.statistics_explanation") }}
				</div>
				<v-progress-linear
					v-for="(item, statusIndex) in $defines.PLAN_STATUS"
					:key="statusIndex"
					striped
					height="25"
					class="my-2"
					:color="item.color"
					:value="item.value"
				>
					{{ $t(`app.${item.label}`) }}
				</v-progress-linear>
			</td>
			<td
				v-for="(value, key, moduleIndex) in group_plan"
				:key="moduleIndex"
				:class="[
					`${getModuleColor(key[1])} lighten-4`,
					'fwb',
					'cursor-wr',
					'title',
				]"
				align="center"
				:style="getSelectedModuleStyle(key)"
				:colspan="getModuleColspan(moduleIndex + 1)"
				@click="selected_module = key"
			>
				{{ key }}
			</td>
		</tr>

		<!-- SECOND ROW -->
		<tr align="center">
			<template v-if="selected_module">
				<td
					v-for="(stage, stage1Index) in stages"
					:key="'stage1' + stage1Index"
					:class="[`${getModuleColor(stage.module.name[1])} lighten-4`, 'fwb']"
					:style="getAlignedStageStyle(stage1Index)"
					:colspan="getStageColspan(stage1Index + 1)"
				>
					{{ stage.name }}
				</td>
			</template>
			<template v-else>
				<td :colspan="groupPlanLength" align="center" class="fwb">
					{{ $t("app.click_module_to_see_progress") }}
				</td>
			</template>
		</tr>

		<!-- THIRD ROW -->
		<tr align="center">
			<td class="fwb">{{ $t("app.s_no") }}</td>
			<td class="fwb">{{ $t("app.first_name") }}</td>
			<td class="fwb">{{ $t("app.last_name") }}</td>
			<td class="fwb">{{ $t("app.explore") }}</td>
			<template v-if="stagesLength">
				<td
					v-for="(stage, stage2Index) in stages"
					:key="'stage2' + stage2Index"
					:colspan="getStageColspan(stage2Index + 1)"
				></td>
			</template>
		</tr>

		<!-- LOOP ROWS -->
		<tr v-for="(student, index) in students" :key="'s' + index" align="center">
			<td>{{ index + 1 }}</td>
			<td>{{ getFirstName(student.user) }}</td>
			<td>{{ getLastName(student.user) }}</td>
			<td>
				<v-btn fab x-small color="success" @click="redirectTo(student.id)">
					<v-icon>
						{{
							$vuetify.icons.values[$vuetify.rtl ? "left_arrow" : "right_arrow"]
						}}
					</v-icon>
				</v-btn>
			</td>

			<template v-if="stagesLength && student.plan">
				<td
					v-for="(stage, stage3Index) in stages"
					:key="'stage3' + stage3Index"
					:colspan="getStageColspan(stage3Index + 1)"
				>
					<!-- If student finishes the stage -->
					<template v-if="getStatus(student.plan, stage.position)">
						<v-progress-linear
							height="20"
							striped
							:color="getStatus(student.plan, stage.position).color"
							:value="getStatus(student.plan, stage.position).value"
						>
						</v-progress-linear>
					</template>
					<!-- If does not finishes the stage -->
					<div v-else class="error--text fwb">
						{{ $t("app.not_finished") }}
					</div>
				</td>
			</template>
			<template v-else>
				<td
					:colspan="
						stagesLength > groupPlanLength ? stagesLength : groupPlanLength
					"
				>
					<div v-if="selected_module" class="pt-4">
						{{ $t("app.plan_not_initiated") }}
					</div>
				</td>
			</template>
		</tr>
	</table>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { sortArrayOfObjects, findFromArray } from "@/utils/helpers";

export default {
	name: "StudentsArrangement",

	props: {
		prop_student_list: {
			required: true,
			type: Array,
		},
	},

	data() {
		return {
			moment,
			loading: false,
			students: null,
			group_plan: null,
			selected_module: null,
			border1: "4px solid var(--v-error-base) !important",
			border2: "1px solid grey !important",
		};
	},

	computed: {
		...mapGetters({
			isAdmin: "user/isAdmin",
			getLastName: "user/getLastName",
			getFirstName: "user/getFirstName",
			getModulesGroup: "planAndManage/getModulesGroup",
			getModuleStagePlan: "planAndManage/getModuleStagePlan",
			getModulesByGroup: "planAndManage/getModulesByGroup",
			getModuleColor: "research/getModuleColor",
		}),

		stages() {
			return this.group_plan[this.selected_module];
		},

		stagesLength() {
			return this.stages ? this.stages.length : 0;
		},

		groupPlanLength() {
			return this.group_plan ? Object.keys(this.group_plan).length : 0;
		},

		studentsLength() {
			return this.prop_student_list.length;
		},
	},

	watch: {
		studentsLength: {
			handler() {
				this.init();
			},
			immediate: true,
		},
	},

	methods: {
		getModuleColspan(module_index) {
			// Only last module should have a clospan
			let is_last_module = module_index == this.groupPlanLength;
			/**
			 * Suppose-
			 * Total stages = 6
			 * Total modules = 5
			 * It means module columns will less than stages. so to fill extra
			 * stage column's gap the last module column's colspan should be
			 * (6 - 5) + 1 (self's) = 2
			 */
			return is_last_module ? this.stagesLength - this.groupPlanLength + 1 : 0;
		},

		getStageColspan(stage_index) {
			// Only last stage should have a clospan
			let is_last_stage = stage_index == this.stagesLength;
			/**
			 * Suppose-
			 * Total modules = 5
			 * Total stages = 3
			 * It means stage columns will less than module, so to fill extra
			 * module column's gap the last stage column's colspan should be
			 * (5 - 3) + 1 (self's) = 3
			 */
			return is_last_stage ? this.groupPlanLength - this.stagesLength + 1 : 0;
		},

		getSelectedModuleStyle(module_name) {
			return module_name == this.selected_module
				? {
						border: this.border1,
						borderBottom: "none !important",
				  }
				: {};
		},

		getAlignedStageStyle(stage_index) {
			/**
			 * If any stage column is aligned with the selected module's
			 * column then the stage column's border should none.
			 */
			let module_index = Object.keys(this.group_plan).findIndex(
				(item) => item == this.selected_module,
			);

			return stage_index == module_index ? {} : { borderTop: this.border1 };
		},

		getStatus(input_plan, position) {
			let response = findFromArray(
				input_plan[this.selected_module],
				"position",
				position,
			);

			if (!response || !response.actual_end_date) return null;

			let planned = moment(response.plan_end_date).format("YYYY-MM-DD");
			let actual = moment(response.actual_end_date).format("YYYY-MM-DD");

			return actual == planned
				? this.$defines.PLAN_STATUS[1]
				: actual > planned
				? this.$defines.PLAN_STATUS[2]
				: this.$defines.PLAN_STATUS[0];
		},

		async getResearchPlanOfUser(param_user_id) {
			return await this.$store.dispatch("planAndManage/getOfStudent", {
				user_id: param_user_id, // This is user profile's id
			});
		},

		init() {
			try {
				this.$loader.start();
				this.loading = true;

				this.students = this.prop_student_list;

				this.students.map(async (item) => {
					if (!this.group_plan && item.group && item.group.group_plan) {
						await this.getGroupPlan(item.group.group_plan);
					}
					// Get student's research plan and sort it
					let plan = await this.getResearchPlanOfUser(item.id);
					plan = sortArrayOfObjects(plan, "id");

					// prepare module structure from group
					plan = this.getModulesGroup(plan);

					// Loop in plan and prepare stages data of each module
					for (const module_name in plan) {
						plan[module_name] = this.getModuleStagePlan(module_name, plan);
					}

					// Now, assign the prepared plan to student's data properties
					item.plan = plan;
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
				this.loading = false;
			}
		},

		redirectTo(param_student_id) {
			try {
				let student = this.prop_student_list.find(
					(item) => item.id == param_student_id,
				);
				// Set it as current student
				this.$store.commit("student/SET_CURRENT_STUDENT", {
					student: student,
				});

				// Push to dedicated route
				this.$router.push({
					name: this.isAdmin ? "AdminStudentOverview" : "StudentOverview",
					params: {
						id: param_student_id,
					},
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async getGroupPlan(plan_id) {
			let response = await this.$store.dispatch("planAndManage/getPlanSteps", {
				plan_id,
			});

			if (response && response.results) {
				this.group_plan = this.getModulesByGroup(response.results);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.table-wr {
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	td,
	th,
	tr {
		border: 1px solid grey !important;
		padding: 10px 15px;
	}
	th {
		background: #ebebed;
	}
}
.fwb {
	font-weight: bold;
}
</style>
