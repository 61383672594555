<template>
	<v-dialog persistent :value="dialog" width="700">
		<AppDialog
			v-model="loading"
			:heading="{
				label: isUserExist
					? $t('app.add_existing_student')
					: $t('app.create_new_student'),
			}"
			:action="{
				label: 'app.save',
				event: 'save',
			}"
			@save="save()"
			@close="$emit('close')"
		>
			<template v-slot:dialog-content>
				<v-breadcrumbs :items="breadcrumbs" class="px-0 pt-0">
					<template v-slot:item="{ item }">
						<v-breadcrumbs-item
							:class="{
								'info--text ': true,
							}"
						>
							{{ $t(item.text) }}
						</v-breadcrumbs-item>
					</template>
					<template v-slot:divider>
						<v-icon>{{
							$vuetify.rtl
								? $vuetify.icons.values.left_chevron
								: $vuetify.icons.values.right_chevron
						}}</v-icon>
					</template>
				</v-breadcrumbs>

				<v-form ref="form">
					<template v-if="!isUserExist">
						<!-- username -->
						<v-text-field
							v-model="fields.login_user"
							outlined
							color="greenAccent2"
							background-color="white"
							:label="$t('app.placeholder.username')"
							:rules="[rules.required]"
						></v-text-field>
						<!-- password -->
						<v-text-field
							v-model="fields.password"
							outlined
							color="greenAccent2"
							background-color="white"
							:label="$t('app.placeholder.password')"
							:type="show_password ? 'text' : 'password'"
							:rules="[rules.required, rules.strongPassword]"
							:append-icon="
								$vuetify.icons.values[show_password ? 'eye' : 'eye_off']
							"
							@click:append="show_password = !show_password"
						></v-text-field>
						<!-- first name -->
						<v-text-field
							v-model="fields.name"
							outlined
							color="greenAccent2"
							:label="$t('app.first_name')"
							:rules="[rules.required]"
						></v-text-field>
						<!-- last name -->
						<v-text-field
							v-model="fields.last_name"
							outlined
							color="greenAccent2"
							background-color="white"
							:label="$t('app.last_name')"
							:rules="[rules.required]"
						></v-text-field>
						<!-- Gender -->
						<v-select
							v-model="fields.gender"
							:items="$defines.GENDER"
							item-text="name"
							item-value="value"
							outlined
							:rules="[rules.required]"
							:label="$t('app.gender')"
							color="greenAccent2"
						>
							<template v-slot:selection="{ item }">
								{{ $t(item.name) }}
							</template>
							<template v-slot:item="{ item }">
								{{ $t(item.name) }}
							</template>
						</v-select>
						<!-- external id -->
						<v-text-field
							v-model.number="fields.external_id"
							:placeholder="$t('app.unique_id')"
							outlined
							color="greenAccent2"
						>
						</v-text-field>
					</template>
					<!-- existing user's id -->
					<template v-else>
						<v-text-field
							v-model="fields.user_id"
							type="number"
							:placeholder="$t('app.user_id')"
							outlined
							:rules="[rules.required]"
							color="greenAccent2"
						>
						</v-text-field>
					</template>
				</v-form>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import AppDialog from "@/components/ui/AppDialog.vue";
import rules from "@/utils/validation";
import { mapState, mapGetters } from "vuex";

export default {
	name: "AddUserDialog",

	props: {
		dialog: {
			required: true,
		},
		isUserExist: {
			required: true,
		},
	},

	data() {
		return {
			rules,
			loading: false,
			show_password: false,
			fields: {
				login_user: null,
				name: null,
				last_name: null,
				password: null,
				user_type: "student",
				gender: null,
				external_id: null,
				user_id: null,
			},
		};
	},

	components: {
		AppDialog,
	},

	computed: {
		...mapState({
			current_group_id: (state) => state.group.current_group_id,
			breadcrumbs: (state) => state.group.breadcrumbs,
		}),
	},

	methods: {
		async save() {
			try {
				if (!this.$refs.form.validate()) return;

				this.loading = true;

				let payload;
				if (this.isUserExist) {
					payload = {
						group: this.current_group_id,
						user_id: this.fields.user_id,
					};
				} else {
					payload = this.fields;
					payload.group = this.current_group_id;
					delete payload["user_id"]; // This does not needed while create
				}

				// dispatch action
				await this.$store.dispatch("group/addUser", payload);

				// emit event to parent component
				this.$emit("on-create", () => {
					this.loading = false;
					this.$refs.form.reset();
				});
			} catch (error) {
				this.loading = false;
				this.$announce.error(error);
			}
		},
	},
};
</script>

<style></style>
