<template>
	<v-card height="100%" flat class="wr_grey_1" v-if="current_group_id">
		<v-card-text class="ps-3">
			<v-row>
				<v-col>
					<div class="greyDarken4--text title">
						{{ $t("app.students_added_to") }}
						<span class="text-decoration-underline">{{
							currentGroup.text
						}}</span>
					</div>
				</v-col>
				<v-col v-if="isAdmin" align="end">
					<AppButton
						label="app.add_existing_student"
						color="info"
						:outlined="true"
						:class="['font-weight-bold']"
						icon="arrow_down"
						@click="(dialog = true), (isUserExist = true)"
					></AppButton>

					<AppButton
						label="app.create_new_student"
						color="info"
						icon="plus"
						:class="['ms-2']"
						@click="(dialog = true), (isUserExist = false)"
					></AppButton>
				</v-col>
			</v-row>
		</v-card-text>

		<!-- If students are available -->
		<div class="ps-2 mt-2">
			<TableView
				v-if="student_list"
				:prop_student_list="student_list"
			></TableView>
			<NoData
				v-else
				heading="app.no_students_yet"
				:show_action="false"
			></NoData>
		</div>

		<!-- Create Group Dialog -->
		<AddUserDialog
			v-if="dialog"
			:dialog="dialog"
			:isUserExist="isUserExist"
			@close="dialog = false"
			@on-create="onAddStudent"
		></AddUserDialog>
	</v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { sortArrayOfObjects } from "@/utils/helpers";
import AppTable from "@/components/ui/AppTable.vue";
import AddUserDialog from "@/components/shared/group/AddUserDialog.vue";
import TableView from "@/components/shared/student/list/Table.vue";
import NoData from "@/components/shared/NoData.vue";

export default {
	name: "StudentsList",

	data() {
		return {
			student_list: null,
			dialog: false,
			isUserExist: false,
		};
	},

	components: {
		AppTable,
		AddUserDialog,
		TableView,
		NoData,
	},

	computed: {
		...mapState({
			current_group_id: (state) => state.group.current_group_id,
			breadcrumbs: (state) => state.group.breadcrumbs,
		}),

		...mapGetters({
			fullName: "user/fullName",
			isAdmin: "user/isAdmin",
		}),

		list() {
			if (this.student_list) {
				return sortArrayOfObjects(this.student_list, "id");
			}
			return this.student_list;
		},

		currentGroup() {
			return this.breadcrumbs[this.breadcrumbs.length - 1];
		},
	},

	watch: {
		current_group_id: {
			handler() {
				this.student_list = null;
				this.getList();
			},
			immediate: true,
		},
	},

	methods: {
		async getList() {
			try {
				this.$loader.start();

				if (!this.current_group_id) return;

				let response = await this.$store.dispatch("group/listUsers", {
					group_id: this.current_group_id,
				});

				if (response && response.length) {
					this.student_list = sortArrayOfObjects(response, "id");
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async onAddStudent(cb) {
			await this.getList();

			cb();

			this.dialog = false;
		},

		redirectTo(student_id) {
			try {
				let student = this.student_list.find((item) => item.id == student_id);
				// Set it as current student
				this.$store.commit("student/SET_CURRENT_STUDENT", {
					student: student,
				});

				// Push to dedicated route
				this.$router.push({
					name: this.isAdmin ? "AdminStudentOverview" : "StudentOverview",
					params: {
						id: student_id,
					},
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>

<style scoped>
.card-wr {
	border: 2px dashed var(--v-amberDarken1-base);
}
</style>
